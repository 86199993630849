<script setup lang="ts">
interface Props {
  open?: any,
  fade?: boolean,
  transitionDuration?: number,
  direction?: 'horizontal' | 'vertical',
}

const props = withDefaults(defineProps<Props>(), {
  open: false,
  fade: true,
  transitionDuration: 500,
  direction: 'vertical',
})

const directionProperty = computed(() => {
  return props.direction === 'vertical' ? 'grid-template-rows' : 'grid-template-columns'
})
</script>

<template>
  <div :style="{
    display: 'grid',
    [directionProperty]: open ? '1fr' : '0fr',
    transition: `${directionProperty} ${transitionDuration}ms`,
  }">
    <div class="overflow-hidden">
      <slot />
    </div>
  </div>
</template>